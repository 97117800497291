<template>
    <div class="solution3" id="jumpPoint">
        <div class="banner">
            <div class="banner_title">
                <p class="p1">塑&nbsp;&nbsp;胶&nbsp;&nbsp;行&nbsp;&nbsp;业&nbsp;&nbsp;解&nbsp;&nbsp;决&nbsp;&nbsp;方&nbsp;&nbsp;案</p>
                <p class="p2">超 越 软 件 智 造 未 来</p>
            </div>
        </div>

        <div class="boxes1">
            <p class="pub_title">简介</p>
            <div class="center">
                <div class="left">
                    <img src="./../../assets/Solution3/img1.png" alt="">
                </div>
                <div v-html="content1" class="content">{{content1}}</div>
            </div>
        </div>

        <div class="boxes2">
            <p class="pub_title" style="margin-bottom:0.3rem;">关键问题</p>
            <img src="./../../assets/Solution3/img2.png" alt="">
            <p class="font1">工作效率低，重复率高且复杂</p>
            <div class="row1">
                <div v-for="(i,index) in 2" :key="index" class="per_tip">原有纸质记录，工作量大， 错误多，追溯困难</div>
            </div>
            <div class="row2">
                <div v-for="(i,index) in 3" :key="index" class="per_tip">有效期只有5天，需要高效的预警</div>
            </div>
        </div>

        <div class="boxes3">
            <p class="pub_title">解决方案优势</p>
            <div class="center">
                <div class="per_row">
                    <div class="left">
                        <img src="./../../assets/Solution3/img3.png" alt="">
                    </div>
                    <div class="right">
                        <p>MES选型标准</p>
                        <div class="line"></div>
                        <div class="content">
                            <span>MES厂商必须具备汽配行业实施经验</span>
                            <span>MES厂商必须熟悉ERP</span>
                            <span>MES厂商必须具备信息化整体规划的能力</span>
                            <span>MES必须能够现有设备互联</span>
                        </div>
                    </div>
                </div>
                <div class="per_row">
                    <div class="left">
                        <img src="./../../assets/Solution3/img4.png" alt="">
                    </div>
                    <div class="right">
                        <p>项目概况</p>
                        <div class="line"></div>
                        <div class="content">
                            <span>企业规模：2000人</span>
                            <span>实施时间：2016.12 </span>
                            <span>实施周期：8个月</span>
                            <span>实施范围：炼胶车间、挤出车间、后工序车间 </span>
                            <span>主要工艺：炼胶、挤出、裁切、焊接、包装等。</span>
                        </div>
                    </div>
                </div>
                <div class="per_row">
                    <div class="left">
                        <img src="./../../assets/Solution3/img5.png" alt="">
                    </div>
                    <div class="right">
                        <p>应用功能</p>
                        <div class="line"></div>
                        <div class="content">
                            <span>炼胶车间投料先进先出管控，投料口控制防错料</span>
                            <span>炼胶车间测试设备数据采集、自动称重</span>
                            <span>仓库电子看板、物料自动配送</span>
                            <span>挤出车间28条产线设备联网、后工序注塑机设备联网</span>
                            <span>仓库亮灯捡货、 车间安灯系统、仓库条码、RFID</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="boxes4">
            <p class="pub_title">实施收益</p>
            <div class="center">
                <div class="left">
                    <div class="content">{{content2}}</div>
                </div>
               <div class="right">
                    <img src="./../../assets/Solution3/img6.png" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default{
    name:'Solution3',
    components:{
        
    },
    data(){
        return {
            content1:'有“研发、模具加工、产品检测试”三大中心，具备独立地产品设计开发及模具设计开发能力；拥有“双钢芯、双色橡胶、海绵、植绒加喷涂七复合连续生产，三维成型，汽车散件模块化组合，高硬度橡胶生产，低密度海绵生产，亮条生产，无缝接口”等核心生产技术。\n&emsp;&emsp;主要客户：上汽大众、上汽通用、广州本田、吉利、五菱……',
            content2:'系统性提升精细化、智能化管理能力，使公司节省了人力成本； 数据准确率达到99%，数据及时率达到100%，真正实现帐物一致； 实施完整闭环生产的连续信息流，实现企业信息全集成，符合精益 生产信息化要求，满足大众等主机厂精益管理要求。',
        }
    },
    
    created(){
        this.$nextTick(()=>{
            this.tojumpPoint()
        })
    },

    methods:{
        tojumpPoint(){
            document.getElementById('jumpPoint').scrollIntoView({
                behavior:'smooth',
                block:'start'
            })
        }
    },
}
</script>

<style scoped lang='scss'>
@import './Solution3.scss'
</style>
